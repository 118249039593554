import React, { useEffect, useState } from 'react'

const TextTextText = ({ containers, block }) => {
  const [contentText, setContentText] = useState([]);
  useEffect(() => {
    setContentText(containers)
  })
  return (
    <div className='row'>
      {(contentText && contentText.length > 0) ?
        <div className="col-md-4 sun-editor-editable">
          <div 
            key="text-text-1"
            dangerouslySetInnerHTML={{
              __html: contentText[0]?.text?.bodyText ? contentText[0].text.bodyText : '',
            }}
          ></div>
        </div>
        :
        <></>
      }
      {(contentText && contentText.length > 1) ?
        <div className="col-md-4">
          <div 
            key="text-text-2"
            dangerouslySetInnerHTML={{
              __html: contentText[1]?.text?.bodyText ? contentText[1].text.bodyText : '',
            }}
          ></div>
        </div>
        :
        <></>
      }
      {(contentText && contentText.length > 2) ?
        <div className="col-md-4">
          <div
            key="text-text-2"
            dangerouslySetInnerHTML={{
              __html: contentText[2]?.text?.bodyText ? contentText[2].text.bodyText : '',
            }}
          ></div>
        </div>
        :
        <></>
      }
    </div>
  )
}

export default TextTextText
