import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function AnonymousFooter({ brandColor, companyName, email, phone, countryCode, subadminId }) {
    return (
        <footer className="full-screen-footer mt-3">
            <Container fluid style={{ backgroundColor: brandColor }}>
                <Row className="justify-content-center">
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-center justify-content-lg-between"
                    >
                        <div className="column-content ml-5">
                            <p
                                style={{
                                    color: "white",
                                    marginTop: "0.5em",
                                    fontSize: "1.2rem",
                                }}
                            >
                                {companyName}
                            </p>
                            <p style={{ color: "white", fontSize: "0.7rem" }}>
                                <a
                                    target="_blank"
                                    href={`/terms-and-conditions?subadminId=${subadminId}`}
                                    style={{ color: "white" }}
                                >
                                    {" "}
                                    CLICK HERE FOR TERMS AND CONDITIONS{" "}
                                </a>
                            </p>
                        </div>
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-center justify-content-lg-end"
                    >
                        <div className="column-content">
                            <p
                                style={{
                                    color: "white",
                                    fontSize: "0.7rem",
                                    marginTop: "1.5rem",
                                }}
                            >
                                PHONE: {countryCode} {phone}
                            </p>
                            <p
                                style={{
                                    color: "white",
                                    fontSize: "0.7rem",
                                    marginBottom: "0rem",
                                }}
                            >
                                EMAIL: {email}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}