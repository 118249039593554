import React from "react";
import moment from "moment";
import JoinEventBtn from "./JoinEventBtn";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function UpcomingEvents({ events, detailsEvent }) {
  const sortEvents = events.sort(function (a, b) {
    const firstEventDate = new Date(a.eventDate);
    const secondEventDate = new Date(b.eventDate);
    return firstEventDate - secondEventDate;
  });
  const handleEventClick = (evnt, e) => {
    // Ensuring that the click did not originate from a specific button element
    if (e.target.closest('.joinBtn') || e.target.closest('button')) {
      return;
    }
    detailsEvent(evnt._id);
  };
  return (
    <div className="upcoming-event">
      <h3 className="title">Upcoming Events</h3>
      <div className="scroll">
        {sortEvents.map((evnt) => {
          var originalUtcTimeStart = new Date(evnt.eventDate);
          var currentDate = new Date();
          var newUtcTimestart = originalUtcTimeStart.toISOString();
          var originalUtcTimeEnd = new Date(evnt.eventEndDate);
          var newUtcTimeEnd = originalUtcTimeEnd.toISOString();
          let eventDate = moment(newUtcTimestart).format("YYYY-MM-DD");
          let eventEndDate = moment(newUtcTimeEnd).format("YYYY-MM-DD");
          let startTime = moment(newUtcTimestart).format("HH:mm");
          let endTime = moment(newUtcTimeEnd).format("HH:mm");

          const userAlreadyJoinedEvent = evnt?.audience?.some(
            (audience) => audience?.user === localStorage.getItem("user_id")
          );

          if (originalUtcTimeEnd > currentDate && (!evnt.hiddenForMembers || (evnt.hiddenForMembers && userAlreadyJoinedEvent)))
            // && (localStorage.getItem("subscriptionId") !='undefined' || evnt.eventShowOnSalesPage)
           {
            return (
              <div
                className="event-list  mt-2 mb-2" role="button"
                onClick={(e) => handleEventClick(evnt, e)}
                style={{
                  borderLeft: `4px solid ${localStorage.getItem(
                    "subadminBrandColor"
                  )}`,
                }}
                key={evnt._id}
              >
                <div className="event-info highlight-card">
                  <div className=" d-flex justify-content-between">
                    <h5 className="event-title">
                      {evnt.name.length > 30 ?
                        (<OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id="tooltip-left">
                              {evnt.name}
                            </Tooltip>
                          }>
                          <span>{evnt.name.substring(0, 25) + '...'}</span>
                        </OverlayTrigger>)
                        :
                        evnt.name}{" "}
                      
                    </h5>
                    {evnt?.eventAcceptIndPayment &&
                        <label className="font-weight-bold">${evnt?.eventAmount}</label>
                      }
                  </div>
                  <div className="d-flex">
                    <span className="event-time">
                      {eventDate} {startTime} | {eventEndDate} {endTime}
                    </span>
                  </div>
                  <div className="mt-2">
                    <JoinEventBtn event={evnt}
                      eventId={evnt._id}
                      alreadyJoined={userAlreadyJoinedEvent} eventAcceptIndPayment={evnt?.eventAcceptIndPayment}
                    />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
